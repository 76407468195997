import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  layout: {
    backgroundColor: theme.palette.common.white,
  },

  video: {
    maxWidth: 454,
    width: '100%',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
  },

  buttons: {
    marginTop: theme.spacing(6),
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
  },

  button: {
    maxWidth: 212,
    width: '100%',
  },
}));
