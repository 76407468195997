import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';
import { ZERO } from 'modules/common/const';
import { getImoTokenBalance } from 'modules/project/api/getImoTokenBalance';
import { getUserEarnedAmount } from 'modules/project/api/getUserEarnedAmount';
import { getUserRewardsAmount } from 'modules/project/api/getUserRewardsAmount';

import { chainId } from '../../api/chainIDs';
import { getUserContributionAmount } from '../api/getUserContributionAmount';

interface IGetUserProjectArgs {
  projectAddress: Address;
}

export interface IGetUserProjectData {
  contributionAmount: BigNumber;
  rewardAmount: BigNumber;
  earnedAmount: BigNumber;
  imoTokenBalance: BigNumber;
}

export const {
  useGetUserProjectQuery,
  endpoints: { getUserProject },
} = api.injectEndpoints({
  endpoints: build => ({
    getUserProject: build.query<IGetUserProjectData, IGetUserProjectArgs>({
      queryFn: async ({ projectAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        const [
          { contributionAmount },
          rewardAmount,
          earnedAmount,
          imoTokenBalance,
        ] = await Promise.all([
          getUserContributionAmount(readProvider, {
            projectAddress,
            contributorAddress: writeProvider.currentAccount,
          }),
          getUserRewardsAmount(readProvider, {
            projectAddress,
            contributorAddress: writeProvider.currentAccount,
          }),
          getUserEarnedAmount(readProvider, {
            projectAddress,
            contributorAddress: writeProvider.currentAccount,
          }),
          getImoTokenBalance(readProvider, {
            projectAddress,
            account: writeProvider.currentAccount,
          }),
        ]);

        return {
          data: {
            contributionAmount,
            rewardAmount,
            imoTokenBalance,
            earnedAmount,
          },
        };
      },
      providesTags: [cacheTags.account, cacheTags.projects],
    }),
  }),
});

export interface IUserProjectStats {
  contributionAmount: BigNumber;
  activeProjectCount: number;
  totalEarnedAmount: BigNumber;
}

export function reduceUserProjects(
  items: (undefined | IGetUserProjectData)[],
): IUserProjectStats {
  return items.reduce(
    (acc, item) => {
      if (!item || item.contributionAmount.isZero()) {
        return acc;
      }

      const earnedAmount = item?.earnedAmount || ZERO;

      return {
        contributionAmount: acc.contributionAmount.plus(
          item ? item.contributionAmount : new BigNumber(0),
        ),
        activeProjectCount: acc.activeProjectCount + 1,
        totalEarnedAmount: acc.totalEarnedAmount.plus(earnedAmount),
      };
    },
    {
      contributionAmount: ZERO,
      totalEarnedAmount: ZERO,
      activeProjectCount: 0,
    },
  );
}
