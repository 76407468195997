import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';

import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { Layout } from 'modules/layout';
import { ProjectRoutesConfig } from 'modules/project/projectRoutesConfig';

import videoUrl from '../../assets/not-found.mp4';
import { translation } from './translation';
import { useStyles } from './useStyles';

export function NotFound(): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes } = useStyles();

  return (
    <Layout className={classes.layout}>
      <Container>
        <div className={classes.root}>
          <video autoPlay loop muted playsInline className={classes.video}>
            <source src={videoUrl} type="video/mp4" />
          </video>

          <Typography mt={3} variant="h4">
            {t(keys.title)}
          </Typography>

          <Typography mt={5} variant="body2">
            {t(keys.description)}
          </Typography>

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              component={Link}
              size="large"
              to={ProjectRoutesConfig.projects.generatePath()}
            >
              {t(keys.discover)}
            </Button>

            <Button
              className={classes.button}
              component={Link}
              size="large"
              to={ProjectRoutesConfig.createProject.generatePath()}
            >
              {t(keys.apply)}
            </Button>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
