import { JSX, useMemo } from 'react';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { LoadingButton, Notification, TextField } from '@ankr.com/ui';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';

import { GET_CONTRIBUTED_TOKEN_LINK } from 'modules/common/const';
import { isIntegerPositive } from 'modules/common/utils/form-validation';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { translation } from './translation';
import { useContributeFormStyles } from './useContributeFormStyles';

export interface IContributePayload {
  contributeAmount: number | string;
}

interface IContributeFormProps {
  onSubmit: ReturnType<UseFormHandleSubmit<IContributePayload, undefined>>;
  onMax?: () => void;
  projectName: string;
  companyName?: string;
  control: Control<IContributePayload>;
  balance?: BigNumber;
  amount: BigNumber;
  multiplier: BigNumber;
  isDirty: boolean;
  isLoading: boolean;
  imoTokenSymbol: string;
  maxImoTokenAmount: BigNumber | null;
  contributeTokenSymbol: string;
}

export function ContributeForm({
  onSubmit,
  onMax,
  projectName,
  companyName,
  control,
  balance,
  amount,
  multiplier,
  isDirty,
  isLoading,
  imoTokenSymbol,
  maxImoTokenAmount,
  contributeTokenSymbol,
}: IContributeFormProps): JSX.Element {
  const { classes } = useContributeFormStyles();
  const { t, keys } = useTranslation(translation);

  const expectedImoTokenAmount = useMemo(() => {
    const expectedAmount = multiplier.multipliedBy(amount);

    if (maxImoTokenAmount && expectedAmount.isGreaterThan(maxImoTokenAmount)) {
      return maxImoTokenAmount;
    }

    return expectedAmount;
  }, [amount, maxImoTokenAmount, multiplier]);

  return (
    <form onSubmit={onSubmit}>
      <Box textAlign="center">
        <Typography component="div" variant="h5">
          {t(keys.title)}
        </Typography>

        {!!companyName && (
          <Typography component="div" mt={3} variant="body2">
            {t(keys.subtitle, {
              projectName,
              companyName,
            })}
          </Typography>
        )}
      </Box>

      <Box className={classes.formContent}>
        <Controller
          control={control}
          name="contributeAmount"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              endLabel={
                <Box className={classes.endLabel}>
                  <Typography
                    className={classes.endLabelText}
                    sx={{ visibility: balance ? 'visible' : 'hidden' }}
                    variant="body2"
                  >
                    {t(keys.yourBalance, {
                      value: balance?.toNumber(),
                    })}
                  </Typography>

                  <Button
                    className={classes.baseButton}
                    component={Link}
                    rel="noreferrer"
                    size="small"
                    target="_blank"
                    to={GET_CONTRIBUTED_TOKEN_LINK}
                    variant="text"
                  >
                    {t(keys.getTokens, { tokenSymbol: contributeTokenSymbol })}
                  </Button>
                </Box>
              }
              error={!!error}
              helperText={error?.message}
              InputProps={{
                inputProps: { step: 1, min: 0 },
                endAdornment: onMax && (
                  <Button
                    className={classes.maxButton}
                    size="small"
                    variant="text"
                    onClick={onMax}
                  >
                    {t(keys.max)}
                  </Button>
                ),
              }}
              label={t(keys.amount)}
              placeholder={t(keys.fieldPlaceholder)}
              type="number"
              value={value}
              onChange={onChange}
            />
          )}
          rules={{
            required: t(keys.validation.required),
            validate: {
              isIntegerPositive: v =>
                isIntegerPositive(v ?? '') ||
                t(keys.validation.requirePositiveInteger),
              isLessThanMaxStakingAmount: v =>
                (balance && v && balance.isGreaterThanOrEqualTo(v)) ||
                t(keys.validation.equalOrLessThan, {
                  value: t(keys.number, {
                    value: balance,
                  }),
                }),
            },
          }}
        />

        <Box className={classes.calculatingBox}>
          <Typography variant="subtitle2">{t(keys.youWillGet)}</Typography>

          <Box className={classes.calculatingData}>
            <Box className={classes.calculatingAmount}>
              <Typography variant="subtitle1">
                {t(keys.tokensAmount, {
                  value: expectedImoTokenAmount.toString(),
                  tokenSymbol: imoTokenSymbol,
                })}
              </Typography>

              <Tooltip
                title={t(keys.tokensRatio, {
                  value1: 1,
                  tokenSymbol1: contributeTokenSymbol,
                  value2: multiplier,
                  tokenSymbol2: imoTokenSymbol,
                })}
              >
                <Button
                  className={classes.baseButton}
                  size="small"
                  variant="text"
                >
                  <Notification sx={{ color: 'inherit' }} />
                </Button>
              </Tooltip>
            </Box>

            {maxImoTokenAmount && !maxImoTokenAmount?.isZero() && (
              <Typography
                className={classes.hintText}
                component="div"
                variant="body2"
              >
                {t(keys.maxTokens, {
                  value: maxImoTokenAmount.toNumber(),
                  tokenSymbol: imoTokenSymbol,
                })}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <LoadingButton
        fullWidth
        disabled={!isDirty}
        loading={isLoading}
        size="large"
        type="submit"
      >
        {t(keys.confirm)}
      </LoadingButton>
    </form>
  );
}
