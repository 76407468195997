import { Components, Theme } from '@mui/material';

export const ONLY_XL_CONTAINER_MAX_WIDTH = 1236;
export const XL_CONTAINER_MAX_WIDTH = 1060;

export function getMuiContainer(theme: Theme): Components['MuiContainer'] {
  return {
    styleOverrides: {
      root: {
        [theme.breakpoints.only('xl')]: {
          maxWidth: ONLY_XL_CONTAINER_MAX_WIDTH,
          paddingLeft: theme.spacing(7.5),
          paddingRight: theme.spacing(7.5),
        },

        [theme.breakpoints.down('xl')]: {
          maxWidth: XL_CONTAINER_MAX_WIDTH,
          paddingLeft: theme.spacing(7.5),
          paddingRight: theme.spacing(7.5),
        },

        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      },
    },
  };
}
