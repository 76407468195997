import axios, { AxiosResponse } from 'axios';

import { api, cacheTags } from 'modules/api';

import {
  API_ENDPOINT,
  API_GET_PROJECTS_PATH,
  STRAPI_API_URL_PARAMS,
} from '../api/const';
import { IProjectApiResponse, IProjectItem } from '../api/types';
import { addDataFromContract } from '../utils/addDataFromContract';
import { getProjectStatus } from '../utils/getProjectStatus';
import { mapProjectFromApi } from '../utils/mapProjectsFromApi';

interface IGetProjectArgs {
  projectId: string;
}

export const { useGetProjectQuery } = api.injectEndpoints({
  endpoints: build => ({
    getProject: build.query<IProjectItem | null, IGetProjectArgs>({
      queryFn: async ({ projectId }) => {
        if (projectId) {
          const handleUrl = `${API_ENDPOINT}${API_GET_PROJECTS_PATH}/${projectId}`;

          const { data: response }: AxiosResponse<IProjectApiResponse> =
            await axios({
              method: 'get',
              url: `${handleUrl}?${STRAPI_API_URL_PARAMS.toString()}`,
            });

          const project = await addDataFromContract(
            mapProjectFromApi(response.data),
          );
          const status = getProjectStatus({
            endDate: project.attributes.investmentEndTime,
            isActive: project.attributes.isActive,
            isSoldOut: project.attributes.isSoldOut,
            isClosed: project.attributes.isClosed,
          });

          return { data: { ...project, status } };
        }

        return { data: null };
      },
      providesTags: [cacheTags.projects],
    }),
  }),
});
