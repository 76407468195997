import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    flex: 1,

    textAlign: 'center',
  },

  token: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
    flex: 1,
  },

  label: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  icon: {
    width: 20,
    height: 20,
  },
}));
