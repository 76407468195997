import { makeStyles } from 'tss-react/mui';

export const useQueryLoadingStyles = makeStyles()(theme => ({
  absolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    padding: theme.spacing(10),
    textAlign: 'center',
  },
}));
