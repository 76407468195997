import { deepmerge } from '@mui/utils';

import { getImoToken } from 'modules/project/api/getImoToken';

import { getReadProvider } from '../../api';
import { chainId } from '../../api/chainIDs';
import { getContribution } from '../api/getContribution';
import { getContributorCount } from '../api/getContributorCount';
import { IProjectItem } from '../api/types';

export async function addDataFromContract(item: IProjectItem) {
  const readProvider = await getReadProvider(chainId);
  const { projectAddress } = item.attributes;

  if (projectAddress) {
    const [investments, imoTokenData, contributorCountData] = await Promise.all(
      [
        getContribution(readProvider, {
          projectAddress,
        }),
        getImoToken(readProvider, { projectAddress }),
        getContributorCount(readProvider, {
          projectAddress,
        }),
      ],
    );

    return deepmerge(item, {
      attributes: {
        investorsCount: contributorCountData.contributorCount,
        investedAmount: investments.amount,
        investedSymbol: investments.symbol,
        investmentGoal: investments.investmentGoal,
        investmentEndTime: investments.investmentEndTime,
        investTokenAddress: investments.investTokenAddress,
        imoTokenSymbol: imoTokenData?.symbol,
        isActive: investments.isActive,
        isSoldOut: investments.isSoldOut,
        isClosed: investments.isClosed,
      },
    });
  }

  return item;
}
