import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
