import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import videoUrl from 'modules/common/assets/success.mp4';
import { DashboardRoutesConfig } from 'modules/dashboard/Routes';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { IContributeData } from 'modules/project/actions/contribute';
import { ContributeDetails } from 'modules/project/components/ContributeDetails';
import { useStyles } from 'modules/project/components/ContributeSuccess/useStyles';

import { translation } from './translation';

interface IContributeSuccessProps {
  contributeData?: IContributeData;
  investedSymbol: string;
  onClose?: () => void;
}

export function ContributeSuccess({
  contributeData,
  investedSymbol,
  onClose,
}: IContributeSuccessProps): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes } = useStyles();

  return (
    <div>
      <video autoPlay muted playsInline className={classes.video}>
        <source src={videoUrl} type="video/mp4" />
      </video>

      <Box className={classes.text}>
        <Typography component="div" variant="h5">
          {t(keys.title)}
        </Typography>
      </Box>

      {contributeData && (
        <ContributeDetails
          investedAmount={contributeData.amount.toString()}
          investedSymbol={investedSymbol}
          projectAddress={contributeData.projectAddress}
          transactionHash={contributeData.transactionHash}
        />
      )}

      <Button
        fullWidth
        component={Link}
        size="large"
        to={DashboardRoutesConfig.dashboard.generatePath()}
        onClick={onClose}
      >
        {t(keys.goToDashboard)}
      </Button>
    </div>
  );
}
