import { Address } from 'web3';

import { getTierProof } from 'modules/staking/api/getTierProof';

import { WriteProvider } from '../../api';
import { createImoContract } from './createImoContract';

export async function claim(
  provider: WriteProvider,
  data: {
    projectAddress: Address;
  },
) {
  const { projectAddress } = data;

  const projectContract = createImoContract(provider, {
    address: projectAddress,
  });

  const tierProof = await getTierProof({
    address: provider.currentAccount,
  });

  const transactionData = projectContract.methods
    .claimIMOTokens(tierProof)
    .encodeABI();

  return provider.sendTransactionAsync(
    provider.currentAccount,
    projectAddress,
    {
      data: transactionData,
      estimate: true,
      estimateFee: true,
    },
  );
}
