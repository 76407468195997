import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { getTierProof } from 'modules/staking/api/getTierProof';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

export async function getUserRewardsAmount(
  provider: ReadProvider,
  {
    projectAddress,
    contributorAddress,
  }: { projectAddress: Address; contributorAddress: Address },
): Promise<BigNumber> {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const tierProof = await getTierProof({ address: contributorAddress });

  const rewards: bigint = await IMOSaleContract.methods
    .getActualTokenAllocation(contributorAddress, tierProof)
    .call();

  return new BigNumber(utils.fromWei(rewards.toString(), 'ether'));
}
