import { JSX } from 'react';
import { Dialog, DialogContent } from '@mui/material';

import { chainId } from 'modules/api/chainIDs';
import { SwitchNetworkGuard } from 'modules/auth/components/SwitchNetworkGuard';
import { WalletConnectGuard } from 'modules/auth/components/WalletConnectGuard';
import { ContributeContent } from 'modules/project/components/ContributeContent';

import { useStyles } from './useStyles';

interface IContributeDialogProps {
  projectId: string;
  open: boolean;
  onClose?: () => void;
}

export function ContributeDialog({
  projectId,
  open = false,
  onClose,
}: IContributeDialogProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
    >
      <WalletConnectGuard>
        <SwitchNetworkGuard chainId={chainId}>
          <DialogContent className={classes.content}>
            <ContributeContent projectId={projectId} onClose={onClose} />
          </DialogContent>
        </SwitchNetworkGuard>
      </WalletConnectGuard>
    </Dialog>
  );
}
