import { ReactElement, useMemo } from 'react';
import { Paper, Typography } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right.svg';
import { useStyles } from './useStyles';

interface ICardProps {
  className?: string;
  title: string;
  description: string;
  link?: string;
  onClick?: () => void;
}

export function Card({
  className,
  title,
  description,
  link,
  onClick,
}: ICardProps): ReactElement {
  const { classes, cx } = useStyles();
  const linkProps = useMemo(() => {
    if (onClick) {
      return { onClick };
    }

    if (link) {
      return {
        component: 'a',
        href: link,
        target: '_blank',
        rel: 'noreferrer',
      };
    }

    return {};
  }, [link, onClick]);

  return (
    <Paper
      {...linkProps}
      className={cx(
        classes.root,
        (link || onClick) && classes.linkRoot,
        className,
      )}
    >
      <Typography
        color={theme => theme.palette.text.primary}
        fontWeight={500}
        variant="h4"
      >
        {title}
      </Typography>

      <Typography color="inherit" fontWeight={500} variant="body2">
        {description}
      </Typography>

      {(link || onClick) && <ArrowRightIcon className={classes.arrow} />}
    </Paper>
  );
}
