import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Plus } from '@ankr.com/ui';
import { Button, IconButton, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import BigNumber from 'bignumber.js';
import { formatDistanceToNowStrict } from 'date-fns';

import { KnownDialogs, useDialog } from 'modules/dialogs';
import { globalTranslation, mergeTranslations } from 'modules/i18n';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { useGetUserProjectQuery } from 'modules/project/actions/getUserProject';
import { IProjectItem, PROJECT_STATUS } from 'modules/project/api/types';
import defaultCoverUrl from 'modules/project/assets/default-cover.png';
import { ClaimModal } from 'modules/project/components/ClaimModal/ClaimModal';
import { ContributeDialog } from 'modules/project/components/ContributeDialog';
import { useHasRewards } from 'modules/project/hooks/useHasRewards';
import { ProjectRoutesConfig } from 'modules/project/projectRoutesConfig';

import { PROJECT_POLLING_INTERVAL } from '../../../common/const';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface IProjectRowProps {
  project: IProjectItem;
}

const mergedTranslation = mergeTranslations(globalTranslation, translation);

export function ProjectRow({
  project: { attributes, id, status },
}: IProjectRowProps): ReactNode {
  const { t, keys } = useTranslation(mergedTranslation);
  const { classes } = useStyles();

  const { onClose, isOpened, onOpen } = useDialog(KnownDialogs.contribute);
  const {
    onClose: onClaimClose,
    isOpened: isClaimOpened,
    onOpen: onClaimOpen,
  } = useDialog(KnownDialogs.claim);

  const name = attributes.projectName;
  const company = attributes.projectCompany?.data?.attributes.companyName;
  const logo =
    attributes.projectGallery?.data?.[0]?.attributes.url ?? defaultCoverUrl;
  const projectAddress = attributes.projectAddress || '';

  const { data: userProjectData, isLoading: isUserProjectLoading } =
    useGetUserProjectQuery(
      projectAddress
        ? {
            projectAddress,
          }
        : skipToken,
      {
        pollingInterval: PROJECT_POLLING_INTERVAL,
        skipPollingIfUnfocused: true,
      },
    );

  const hasRewards = useHasRewards(id);

  if (
    isUserProjectLoading ||
    !userProjectData ||
    userProjectData.contributionAmount.isZero()
  ) {
    return null;
  }

  const apy = 0;
  const { contributionAmount } = userProjectData;
  const contributionAmountUsd = new BigNumber(0);
  const tokensAmount = userProjectData.rewardAmount;
  const tokensAmountUsd = new BigNumber(0);

  return (
    <tr className={classes.root}>
      <td className={classes.projectColumn}>
        <Link
          className={classes.logoContainer}
          to={ProjectRoutesConfig.project.generatePath(id)}
        >
          <img alt="" className={classes.logo} src={logo} />
        </Link>

        <div>
          <Typography component="div" variant="subtitle1">
            {name}
          </Typography>

          {company && (
            <Typography component="div" variant="body3">
              {company}
            </Typography>
          )}
        </div>
      </td>

      <td>
        <div>
          <Typography component="div" variant="body3">
            {t(keys.unit.tokenValue, {
              token: attributes?.investedSymbol,
              value: contributionAmount.decimalPlaces(2).toString(),
            })}
          </Typography>

          {contributionAmountUsd && !contributionAmountUsd?.isZero() && (
            <Typography className={classes.usdAmount} component="div">
              {t(keys.unit.$, {
                value: contributionAmountUsd.decimalPlaces(2).toString(),
              })}
            </Typography>
          )}
        </div>

        <IconButton
          className={classes.iconButton}
          sx={{ ml: 2 }}
          onClick={() => onOpen()}
        >
          <Plus htmlColor="inherit" />
        </IconButton>
      </td>

      <td>
        <Typography component="div" variant="body3">
          {apy ? t(keys.unit.percent, { value: apy }) : '-'}
        </Typography>
      </td>

      <td className={classes.rewardColumn}>
        <div className={classes.rewardedAmount}>
          <Typography component="div" variant="body3">
            {t(keys.unit.tokenValue, {
              token: attributes.imoTokenSymbol,
              value: tokensAmount.decimalPlaces(2).toString(),
            })}
          </Typography>

          {tokensAmountUsd && !contributionAmountUsd?.isZero() && (
            <Typography className={classes.usdAmount} component="div">
              {t(keys.unit.$, {
                value: tokensAmountUsd.decimalPlaces(2).toString(),
              })}
            </Typography>
          )}
        </div>

        {hasRewards && status === PROJECT_STATUS.closed && (
          <>
            <Button
              color="info"
              size="medium"
              sx={{ mr: 6 }}
              onClick={() => onClaimOpen()}
            >
              {t(keys.claim)}
            </Button>

            {isClaimOpened && (
              <ClaimModal
                open={isClaimOpened}
                projectId={id}
                onClose={() => onClaimClose()}
              />
            )}
          </>
        )}

        {hasRewards && status !== PROJECT_STATUS.closed && (
          <Button disabled color="info" size="medium" sx={{ mr: 6 }}>
            {t(keys.canClaimIn, {
              distance: formatDistanceToNowStrict(attributes.investmentEndTime),
            })}
          </Button>
        )}
      </td>

      {isOpened && (
        <ContributeDialog
          open={isOpened}
          projectId={id}
          onClose={() => onClose()}
        />
      )}
    </tr>
  );
}
