import { Address } from 'web3';

import { ReadProvider, WriteProvider } from '../../api';
import IERC7641Abi from './abi/IERC7641';

export function createImoTokenContract(
  provider: ReadProvider | WriteProvider,
  { address }: { address: Address },
) {
  return provider.createContract<typeof IERC7641Abi>(IERC7641Abi, address);
}
