import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ZERO, ZERO_ADDRESS } from 'modules/common/const';
import { createImoTokenContract } from 'modules/project/api/createImoTokenContract';
import { getImoToken } from 'modules/project/api/getImoToken';

import { ReadProvider } from '../../api';

export interface IGetImoTokenBalanceArgs {
  projectAddress: Address;
  account: Address;
}

export async function getImoTokenBalance(
  provider: ReadProvider,
  { projectAddress, account }: IGetImoTokenBalanceArgs,
): Promise<BigNumber> {
  const { address } = await getImoToken(provider, { projectAddress });
  if (address === ZERO_ADDRESS) {
    return ZERO;
  }

  const imoTokenContract = createImoTokenContract(provider, { address });

  const rawAmount: bigint = await imoTokenContract.methods
    .balanceOf(account)
    .call();

  return new BigNumber(utils.fromWei(rawAmount.toString(), 'ether'));
}
