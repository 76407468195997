import { isFuture, isPast } from 'date-fns';

import { PROJECT_STATUS } from '../api/types';

interface IGetProjectStatusProps {
  endDate?: Date;
  isActive?: boolean;
  isSoldOut: boolean;
  isClosed: boolean;
}

export const getProjectStatus = ({
  endDate,
  isActive,
  isSoldOut,
  isClosed,
}: IGetProjectStatusProps): PROJECT_STATUS => {
  if (isClosed || (!!endDate && isPast(endDate))) {
    return PROJECT_STATUS.closed;
  }

  if (isSoldOut) {
    return PROJECT_STATUS.soldOut;
  }

  if (!isActive && endDate && isFuture(endDate)) {
    return PROJECT_STATUS.comingSoon;
  }

  if (isActive && !!endDate && isFuture(endDate)) {
    return PROJECT_STATUS.active;
  }

  return PROJECT_STATUS.comingSoon;
};
