import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    destinationAddress: 'Destination address',
    txID: 'Transaction ID',
    tokensAmount:
      '{value, number, ::.########} {tokenSymbol} {value, plural, =1 {Token} other {Tokens}}',
    amount: 'Amount',
  },
};
