import React, { JSX } from 'react';
import { OverlaySpinner } from '@ankr.com/ui';
import { Box } from '@mui/material';

import { useQueryLoadingStyles } from './useQueryLoadingStyles';

interface IQueryLoadingProps {
  className?: string;
  size?: number;
}

export function QueryLoadingAbsolute({
  size,
  className,
}: IQueryLoadingProps): JSX.Element {
  const { classes, cx } = useQueryLoadingStyles();

  return (
    <OverlaySpinner className={cx(classes.absolute, className)} size={size} />
  );
}

export function QueryLoadingCentered({ size, className }: IQueryLoadingProps) {
  const { classes } = useQueryLoadingStyles();

  return (
    <Box className={classes.centered} display="flex" justifyContent="center">
      <OverlaySpinner className={className} size={size} />
    </Box>
  );
}
