import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Claim reward',
    subtitle: 'You’re going to receive:',
    tokens: '{tokenSymbol} {value, plural, =1 {Token} other {Tokens}}',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};
