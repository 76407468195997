import { Components, Theme } from '@mui/material';

export function getMuiDialogContent(
  theme: Theme,
): Components['MuiDialogContent'] {
  return {
    styleOverrides: {
      root: {
        '&&': {
          padding: theme.spacing(7.5),
        },
      },
    },
  };
}
