import { Address } from 'web3';

import { api, cacheTags, getWriteProvider } from 'modules/api';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { claim } from 'modules/project/api/claim';

interface IContributeArgs {
  projectAddress: Address;
}

export const { useClaimMutation } = api.injectEndpoints({
  endpoints: build => ({
    claim: build.mutation<boolean, IContributeArgs>({
      queryFn: queryFnNotifyWrapper<IContributeArgs, void, boolean>(
        async ({ projectAddress }) => {
          const writeProvider = await getWriteProvider();

          const result = await claim(writeProvider, {
            projectAddress,
          });

          void (await result.receiptPromise);

          return {
            data: true,
          };
        },
      ),
      invalidatesTags: [cacheTags.account, cacheTags.projects],
    }),
  }),
});
