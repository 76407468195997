import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ZERO, ZERO_ADDRESS } from 'modules/common/const';
import { createImoTokenContract } from 'modules/project/api/createImoTokenContract';
import { getContributionToken } from 'modules/project/api/getContributionToken';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

interface IGetUserEarnedAmountArgs {
  projectAddress: Address;
  contributorAddress: Address;
}

export async function getUserEarnedAmount(
  provider: ReadProvider,
  { projectAddress, contributorAddress }: IGetUserEarnedAmountArgs,
): Promise<BigNumber> {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const imoTokenAddress: string = await IMOSaleContract.methods
    .getImoTokenAddress()
    .call();

  if (imoTokenAddress === ZERO_ADDRESS) {
    return ZERO;
  }

  const imoTokenContract = createImoTokenContract(provider, {
    address: imoTokenAddress,
  });

  const [{ decimals }, totalEarned] = await Promise.all([
    getContributionToken(provider, { projectAddress }),
    imoTokenContract.methods.getTotalEarned(contributorAddress).call<bigint>(),
  ]);

  return new BigNumber(utils.fromWei(totalEarned, Number(decimals)));
}
