import { IWeb3SendResult } from '@ankr.com/provider/src/providerManager/providers/Web3KeyWriteProvider';
import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { queryFnNotifyWrapper } from '../../common/utils/queryFnNotifyWrapper';
import { contribute } from '../api/contribute';
import { getAllowance } from '../api/getAllowance';
import { setAllowance } from '../api/setAllowance';

export interface IContributeData extends IWeb3SendResult {
  amount: BigNumber;
  projectAddress: string;
}

interface IContributeArgs {
  projectAddress: Address;
  amount: BigNumber;
}

export const { useContributeMutation } = api.injectEndpoints({
  endpoints: build => ({
    contribute: build.mutation<IContributeData, IContributeArgs>({
      queryFn: queryFnNotifyWrapper<IContributeArgs, void, IContributeData>(
        async ({ projectAddress, amount }) => {
          const writeProvider = await getWriteProvider();
          const readProvider = await getReadProvider(chainId);
          const { allowance, investTokenAddress, investTokenDecimal } =
            await getAllowance(readProvider, {
              userAddress: writeProvider.currentAccount,
              spender: projectAddress,
            });

          if (!allowance.isGreaterThanOrEqualTo(amount)) {
            const result = await setAllowance(writeProvider, {
              spender: projectAddress,
              assetAddress: investTokenAddress,
              amount,
              investTokenDecimal,
            });

            void (await result.receiptPromise);
          }

          // TODO: Sometimes transaction initialisation fails `execution reverted: ERC20: insufficient allowance`
          await new Promise(resolve => setTimeout(resolve, 12000));

          return {
            data: {
              ...(await contribute(writeProvider, {
                projectAddress,
                amount,
                investTokenDecimal,
              })),
              amount,
              projectAddress,
            },
          };
        },
      ),
      invalidatesTags: [cacheTags.account],
    }),
  }),
});
