import { ReactElement } from 'react';
import { ExternalLink } from '@ankr.com/ui';
import { Box, IconButton } from '@mui/material';

import { getTransactionDetailsLink } from 'modules/common';
import { CopyButton } from 'modules/common/components/CopyButton';
import { cropString } from 'modules/common/utils/cropString';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { translation } from './translation';
import { useStyles } from './useStyles';

export interface IContributeDetailsProps {
  projectAddress: string;
  investedAmount: string;
  investedSymbol: string;
  transactionHash: string;
}

export function ContributeDetails({
  projectAddress,
  investedSymbol,
  investedAmount,
  transactionHash,
}: IContributeDetailsProps): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.listBody}>
        <div className={classes.listRow}>
          <div className={classes.listRowLeft}>{t(keys.amount)}</div>

          <div className={classes.listRowRight}>
            {t(keys.tokensAmount, {
              value: investedAmount,
              tokenSymbol: investedSymbol,
            })}
          </div>
        </div>

        <div className={classes.listRow}>
          <div className={classes.listRowLeft}>
            {t(keys.destinationAddress)}
          </div>

          <div className={classes.listRowRight}>
            <Box alignItems="center" display="flex" gap={2}>
              {cropString(projectAddress, 8)}

              <CopyButton size="small" text={projectAddress} />
            </Box>
          </div>
        </div>

        <div className={classes.listRow}>
          <div className={classes.listRowLeft}>{t(keys.txID)}</div>

          <div className={classes.listRowRight}>
            <Box alignItems="center" display="flex" gap={2}>
              {cropString(transactionHash, 8)}

              <CopyButton size="small" text={transactionHash} />

              <IconButton
                component="a"
                href={getTransactionDetailsLink(transactionHash)}
                rel="noreferrer"
                size="small"
                target="_blank"
              >
                <ExternalLink />
              </IconButton>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
