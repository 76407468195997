import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { getTokenRewardsMultiplier as getTokenRewardsMultiplierApi } from '../api/getTokenRewardsMultiplier';

interface IGetTokenRewardsMultiplierArgs {
  projectAddress: Address;
}

interface IGetTokenRewardsMultiplierData {
  multiplier: BigNumber;
}

export const {
  useGetTokenRewardsMultiplierQuery,
  endpoints: { getTokenRewardsMultiplier },
} = api.injectEndpoints({
  endpoints: build => ({
    getTokenRewardsMultiplier: build.query<
      IGetTokenRewardsMultiplierData,
      IGetTokenRewardsMultiplierArgs
    >({
      queryFn: async ({ projectAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        return {
          data: await getTokenRewardsMultiplierApi(readProvider, {
            contributorAddress: writeProvider.currentAccount,
            projectAddress,
          }),
        };
      },
      providesTags: [cacheTags.account],
    }),
  }),
});
