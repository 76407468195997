import { memoizePromise } from 'modules/common/utils/memoizePromise';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

export interface IGetImoToken {
  symbol: string;
  address: string;
}

export async function _getImoToken(
  provider: ReadProvider,
  { projectAddress }: { projectAddress: string },
): Promise<IGetImoToken> {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const [symbol, address] = await Promise.all([
    IMOSaleContract.methods.imoTokenSymbol().call(),
    IMOSaleContract.methods.getImoTokenAddress().call(),
  ]);

  return {
    symbol,
    address,
  };
}

export const getImoToken = memoizePromise(
  _getImoToken,
  (provider: ReadProvider, args: { projectAddress: string }) => {
    return `${provider.chainId}${provider.rpcUrl}${args.projectAddress}`;
  },
);
