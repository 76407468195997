import { Components, Theme } from '@mui/material/styles';

export function getMuiTableCell(theme: Theme): Components['MuiTableCell'] {
  return {
    styleOverrides: {
      root: {
        '&&:first-of-type': {
          paddingLeft: 0,
        },
        '&&:last-of-type': {
          paddingRight: 0,
        },
      },
      head: {
        backgroundColor: 'transparent',
        fontSize: 14,
        fontWeight: 400,
        '&&': {
          padding: theme.spacing(2.5, 2),
        },
      },
      body: {
        fontSize: 14,
        fontWeight: 400,
        borderTop: `1px solid ${theme.palette.secondary.light}`,
        '&&': {
          borderRadius: 0,
          padding: theme.spacing(2.75, 2),
        },
      },
    },
  };
}
