import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { getBalance } from '../api/getBalance';

interface IGetBalanceArgs {
  tokenAddress: Address;
}

export const { useGetBalanceQuery } = api.injectEndpoints({
  endpoints: build => ({
    getBalance: build.query<BigNumber, IGetBalanceArgs>({
      queryFn: async ({ tokenAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        const { value } = await getBalance(readProvider, {
          userAddress: writeProvider.currentAccount,
          tokenAddress,
        });

        return {
          data: value,
        };
      },
      providesTags: [cacheTags.account, cacheTags.projects],
    }),
  }),
});
