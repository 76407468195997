import { ReactElement } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import { NotFound } from 'modules/common/components/NotFound';
import {
  DashboardRoutesConfig,
  getDashboardRoutes,
} from 'modules/dashboard/Routes';

import { getProjectRoutes } from '../project/getProjectRoutes';
import { RootRoute } from './RootRoute';

export function Routes(): ReactElement {
  return (
    <Switch>
      <Route element={<NotFound />} path="*" />

      <Route element={<RootRoute />} path="/">
        {getDashboardRoutes()}

        {getProjectRoutes()}

        <Route
          element={
            <Navigate to={DashboardRoutesConfig.dashboard.generatePath()} />
          }
          path="*"
        />
      </Route>
    </Switch>
  );
}
