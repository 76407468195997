import { JSX } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { getTransactionDetailsLink } from 'modules/common';
import { cropString } from 'modules/common/utils/cropString';
import { globalTranslation, mergeTranslations } from 'modules/i18n';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { ProjectRoutesConfig } from 'modules/project/projectRoutesConfig';

import { IInvestedRes } from '../../actions/getInvested';
import { translation } from './translation';

const SHOW_DATE_COLUMN = false;

const mergedTranslation = mergeTranslations(globalTranslation, translation);

interface ITransactionHistoryContentProps {
  items?: IInvestedRes[];
}

export function TransactionHistoryContent({
  items,
}: ITransactionHistoryContentProps): JSX.Element {
  const { t, keys } = useTranslation(mergedTranslation);
  const theme = useTheme();

  if (!items || !items.length) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight={200}
      >
        {t(keys.noData)}
      </Box>
    );
  }

  return (
    <div>
      <Typography sx={{ mb: 2.5 }} variant="h5">
        {t(keys.title)}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{t(keys.header.project)}</TableCell>

              {SHOW_DATE_COLUMN && (
                <TableCell variant="head">{t(keys.header.date)}</TableCell>
              )}

              <TableCell variant="head">{t(keys.header.hash)}</TableCell>

              <TableCell variant="head">{t(keys.header.amount)}</TableCell>

              <TableCell variant="head">{t(keys.header.rewards)}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map(item => (
              <TableRow key={item.txHash || item.blockNumber}>
                <TableCell variant="body">
                  <Box
                    component={RouterLink}
                    maxWidth={100}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    to={ProjectRoutesConfig.project.generatePath(
                      item.projectId,
                    )}
                    whiteSpace="nowrap"
                  >
                    {item.projectName}
                  </Box>
                </TableCell>

                {SHOW_DATE_COLUMN && (
                  <TableCell variant="body">
                    {item.date ? (
                      <>
                        {t(keys.content.date, { value: item.date })}

                        <Typography
                          color={theme.palette.grey['500']}
                          display="block"
                          variant="body3"
                        >
                          {t(keys.content.time, { value: item.date })}
                        </Typography>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                )}

                <TableCell variant="body">
                  {item.txHash ? (
                    <Link
                      href={getTransactionDetailsLink(item.txHash)}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {cropString(item.txHash, 8)}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>

                <TableCell variant="body">
                  {t(keys.unit.tokenValue, {
                    value: item.amount,
                    token: item.tokenSymbol,
                  })}
                </TableCell>

                <TableCell variant="body">
                  {item.rewardsAmount && !item.rewardsAmount.isZero()
                    ? t(keys.unit.tokenValue, {
                        value: item.rewardsAmount?.toFormat(),
                        token: item.rewardsTokenSymbol,
                      })
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
