import BigNumber from 'bignumber.js';
import { utils } from 'web3';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';
import { getContributionToken } from './getContributionToken';

export async function getContributionTokenAmount(
  provider: ReadProvider,
  { projectAddress }: { projectAddress: string },
) {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const { decimals } = await getContributionToken(provider, { projectAddress });

  const balance: bigint = await IMOSaleContract.methods.totalFunds().call();

  return {
    amount: new BigNumber(utils.fromWei(balance, decimals)),
  };
}
