import axios, { AxiosResponse } from 'axios';

import { api, cacheTags } from 'modules/api';

import {
  API_ENDPOINT,
  API_GET_PROJECTS_PATH,
  STRAPI_API_URL_PARAMS,
} from '../api/const';
import { IProjects, IProjectsApiResponse, PROJECT_STATUS } from '../api/types';
import { addDataFromContract } from '../utils/addDataFromContract';
import { getProjectStatus } from '../utils/getProjectStatus';
import { mapProjectsFromApi } from '../utils/mapProjectsFromApi';

interface IGetProjectsProps {
  page?: string | number;
  pageSize?: string | number;
}

export const { useGetProjectsQuery } = api.injectEndpoints({
  endpoints: build => ({
    getProjects: build.query<IProjects, IGetProjectsProps | undefined>({
      queryFn: async ({ page, pageSize = 100 } = {}) => {
        const handleUrl = `${API_ENDPOINT}${API_GET_PROJECTS_PATH}`;

        STRAPI_API_URL_PARAMS.set('sort', 'createdAt:desc');
        if (pageSize) {
          STRAPI_API_URL_PARAMS.set(
            'pagination[pageSize]',
            pageSize.toString(),
          );
        }
        if (page) {
          STRAPI_API_URL_PARAMS.set('pagination[page]', page.toString());
        }

        const { data: response }: AxiosResponse<IProjectsApiResponse> =
          await axios({
            method: 'get',
            url: `${handleUrl}?${STRAPI_API_URL_PARAMS.toString()}`,
          });

        const data = (
          await Promise.all(
            mapProjectsFromApi(response.data).map(async item => {
              const project = await addDataFromContract(item);
              const status = getProjectStatus({
                endDate: project.attributes.investmentEndTime,
                isActive: project.attributes.isActive,
                isSoldOut: project.attributes.isSoldOut,
                isClosed: project.attributes.isClosed,
              });

              return { ...project, status };
            }),
          )
        ).sort(function activeOnTop(item) {
          if (item.status === PROJECT_STATUS.active) {
            return -1;
          }

          return 1;
        });

        return {
          data: {
            data,
            meta: response.meta,
          },
        };
      },
      providesTags: [cacheTags.projects],
    }),
  }),
});
