import BigNumber from 'bignumber.js';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';
import { chainId } from 'modules/api/chainIDs';
import { ZERO } from 'modules/common/const';
import { getUserRewardsAmount } from 'modules/project/api/getUserRewardsAmount';

interface IGetRewardsArgs {
  projectAddress: string;
}

export const { useGetRewardsQuery } = api.injectEndpoints({
  endpoints: build => ({
    getRewards: build.query<BigNumber, IGetRewardsArgs>({
      queryFn: async ({ projectAddress }) => {
        if (projectAddress) {
          const readProvider = await getReadProvider(chainId);
          const writeProvider = await getWriteProvider();

          return {
            data: await getUserRewardsAmount(readProvider, {
              projectAddress,
              contributorAddress: writeProvider.currentAccount,
            }),
          };
        }

        return { data: ZERO };
      },
      providesTags: [cacheTags.account, cacheTags.projects],
    }),
  }),
});
