import { PointTier } from '@ankr.com/points';
import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { ZERO } from 'modules/common/const';

import { PointsApi, ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

export async function getTokenRewardsMultiplier(
  provider: ReadProvider,
  {
    contributorAddress,
    projectAddress,
  }: {
    contributorAddress: Address;
    projectAddress: Address;
  },
) {
  const { currentTier } =
    await PointsApi.getInstance().getAccountTier(contributorAddress);

  async function getMultiplier() {
    if (currentTier === PointTier.NoTier) {
      return ZERO;
    }

    const IMOSaleContract = createImoContract(provider, {
      address: projectAddress,
    });

    const multiplier = await IMOSaleContract.methods
      .tokenAllocationMultiplier()
      .call();

    return new BigNumber(multiplier.toString());
  }

  return {
    multiplier: await getMultiplier(),
  };
}
