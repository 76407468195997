import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(5, 6),
    borderRadius: 18,
    transition: 'color 0.3s',
  },

  linkRoot: {
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },

    '&:not(:hover)': {
      '& svg': {
        color: theme.palette.grey[500],
      },
    },
  },

  arrow: {
    position: 'absolute',
    right: 20,
    bottom: 24,
  },
}));
