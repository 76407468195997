import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Contribute to project.',
    subtitle: '{projectName} by {companyName}',
    max: 'MAX',
    amount: 'Amount',
    fieldPlaceholder: '0',
    validation: {
      required: 'This field is required',
      requirePositiveInteger: 'This value should be a positive whole number',
      equalOrLessThan: 'This value should be equal or less than {value}',
    },
    tokensAmount:
      '{value, number, ::.########} {tokenSymbol} {value, plural, =1 {Token} other {Tokens}}',
    maxTokens:
      'Max {tokenSymbol} Tokens available: {value, number, ::.########}',
    confirm: 'Confirm and contribute',
    number: '{value, number, ::.########}',
    tokensRatio:
      '{value1, number, ::.########} {tokenSymbol1} = {value2, number, ::.########} {tokenSymbol2} {value2, plural, =1 {Token} other {Tokens}}',
    youWillGet: 'You will get',
    yourBalance: 'Your balance {value, number, ::.########}',
    getTokens: 'Get {tokenSymbol}',
  },
};
