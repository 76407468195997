import { Components, Theme } from '@mui/material/styles';

export function getMuiLink(theme: Theme): Components['MuiLink'] {
  return {
    styleOverrides: {
      root: {
        '&, &:visited': {
          color: theme.palette.primary.main,
        },
      },
    },
  };
}
