import { ReactElement, ReactNode } from 'react';

import { WalletsDialog } from 'modules/auth/components/WalletsDialog';
import { useScrollToTop } from 'modules/common/hooks/useScrollToTop';

import { Header } from '../Header';
import { useStyles } from './useStyles';

interface Props {
  className?: string;
  children: ReactElement | ReactNode;
}

export function Layout({ className, children }: Props): ReactElement {
  const { classes, cx } = useStyles();
  useScrollToTop();

  return (
    <div className={cx(classes.root, className)}>
      <Header />

      <div className={classes.content}>{children}</div>

      <WalletsDialog />
    </div>
  );
}
