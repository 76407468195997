import { ReactElement, useCallback } from 'react';
import { LoadingButton } from '@ankr.com/ui';
import { Button, CircularProgress, Typography } from '@mui/material';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { useClaimMutation } from 'modules/project/actions/claim';
import { useGetProjectQuery } from 'modules/project/actions/getProject';
import { useGetRewardsQuery } from 'modules/project/actions/getRewards';

import imoTokenIconUrl from '../../assets/imo-token-logo.png';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface IClaimContentProps {
  projectId: string;
  onClose?: () => void;
}

export function ClaimContent({
  projectId,
  onClose,
}: IClaimContentProps): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes } = useStyles();

  const { isConnected } = useConnection();
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    projectId,
  });
  const projectAddress = project?.attributes.projectAddress || '';

  const { data: imoRewardAmount = ZERO, isLoading: isClaimableRewardLoading } =
    useGetRewardsQuery(
      { projectAddress },
      { skip: !isConnected || !projectAddress },
    );
  const [claim, { isLoading: isClaimLoading }] = useClaimMutation();

  const isClaimDisabled =
    imoRewardAmount.isZero() || isProjectLoading || !isConnected;

  const imoTokenSymbol = project?.attributes?.imoTokenSymbol || '';

  const handleClaim = useCallback(async () => {
    const { data } = await claim({ projectAddress });
    if (data) {
      onClose?.();
    }
  }, [claim, onClose, projectAddress]);

  return (
    <div className={classes.root}>
      <Typography variant="h5">{t(keys.title)}</Typography>

      <Typography variant="body2">{t(keys.subtitle)}</Typography>

      <div className={classes.token}>
        {isClaimableRewardLoading || isProjectLoading ? (
          <CircularProgress size={52} />
        ) : (
          <>
            <Typography variant="h3">{imoRewardAmount.toString()}</Typography>

            <div className={classes.label}>
              <img alt="" className={classes.icon} src={imoTokenIconUrl} />

              <span>
                {t(keys.tokens, {
                  tokenSymbol: imoTokenSymbol,
                  value: imoRewardAmount.toNumber(),
                })}
              </span>
            </div>
          </>
        )}
      </div>

      {onClose && (
        <Button fullWidth color="info" size="extraLarge" onClick={onClose}>
          {t(keys.cancel)}
        </Button>
      )}

      <LoadingButton
        fullWidth
        disabled={isClaimDisabled}
        loading={isClaimLoading}
        size="extraLarge"
        onClick={handleClaim}
      >
        {t(keys.confirm)}
      </LoadingButton>
    </div>
  );
}
