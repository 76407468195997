import { JSX } from 'react';
import { Dialog, DialogContent } from '@mui/material';

import { useConnection } from '../../../auth/hooks/useConnection';
import { QueryLoadingCentered } from '../../../common/components/QueryLoading';
import { IProjects } from '../../../project/api/types';
import { useGetInvestedQuery } from '../../actions/getInvested';
import { TransactionHistoryContent } from '../TransactionHistoryContent';

interface ITransactionHistoryProps {
  open: boolean;
  onClose?: () => void;
  projects?: IProjects;
}

export function TransactionHistoryDialog({
  open = false,
  onClose,
  projects,
}: ITransactionHistoryProps): JSX.Element {
  const { isConnected } = useConnection();

  const { data, isLoading } = useGetInvestedQuery(
    { projects },
    {
      skip: !isConnected || !projects,
    },
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        {isLoading ? (
          <QueryLoadingCentered />
        ) : (
          <TransactionHistoryContent items={data} />
        )}
      </DialogContent>
    </Dialog>
  );
}
