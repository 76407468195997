import { getPastEvents } from '@ankr.com/advanced-api';
import BigNumber from 'bignumber.js';
import { TransactionHash, utils } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';
import { getExtendedErrorText } from 'modules/common/utils/getExtendedErrorText';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { t } from 'modules/i18n';

import { chainId } from '../../api/chainIDs';
import { createImoContract } from '../../project/api/createImoContract';
import { getInvestToken } from '../../project/api/getInvestToken';
import { IProjects } from '../../project/api/types';

interface IGetInvestedProps {
  projects?: IProjects;
}

export interface IInvestedRes {
  projectId: string;
  projectName: string;
  date?: Date;
  blockNumber?: number;
  txHash?: TransactionHash;
  tokenSymbol?: string;
  amount?: BigNumber;
  rewardsAmount?: BigNumber;
  rewardsTokenSymbol?: string;
}

export const { useGetInvestedQuery } = api.injectEndpoints({
  endpoints: build => ({
    getInvested: build.query<IInvestedRes[], IGetInvestedProps>({
      queryFn: queryFnNotifyWrapper<IGetInvestedProps, never, IInvestedRes[]>(
        async ({ projects }) => {
          const writeProvider = await getWriteProvider();
          const readProvider = await getReadProvider(chainId);

          const web3 = readProvider.getWeb3();

          const address = writeProvider.currentAccount;

          const result: IInvestedRes[] = [];

          if (projects) {
            for await (const project of projects.data) {
              const projectId = project.id;
              const { projectName, projectAddress, investedSymbol } =
                project.attributes;

              if (projectAddress) {
                const { investTokenDecimal } = await getInvestToken(
                  readProvider,
                  projectAddress,
                );

                const IMOSaleContract = createImoContract(readProvider, {
                  address: projectAddress,
                });

                const rewardsTokenSymbol = await IMOSaleContract.methods
                  .imoTokenSymbol()
                  .call();

                const latestBlock = await readProvider.getBlockNumber();

                const events = await getPastEvents({
                  apiUrl: import.meta.env.VITE_AAPI_URL,
                  fromBlock: 0,
                  toBlock: Number(latestBlock),
                  blockchain: import.meta.env.VITE_BLOCKCHAIN,
                  contract: IMOSaleContract,
                  web3,
                  eventName: 'InvestmentReceived',
                  filter: { investor: address },
                });

                events.forEach(event => {
                  if (!event.returnValues) {
                    return;
                  }

                  const {
                    amount: eventsAmount,
                    eventsTokenAllocationMultiplier,
                  } = event.returnValues;

                  const amount = eventsAmount
                    ? new BigNumber(
                        utils.fromWei(
                          <string>eventsAmount,
                          Number(investTokenDecimal),
                        ),
                      )
                    : undefined;

                  const tokenAllocationMultiplier =
                    eventsTokenAllocationMultiplier
                      ? new BigNumber(<string>eventsTokenAllocationMultiplier)
                      : undefined;

                  const rewardsAmount =
                    amount && tokenAllocationMultiplier
                      ? amount.multipliedBy(tokenAllocationMultiplier)
                      : undefined;

                  result.push({
                    projectId,
                    projectName,
                    date: undefined,
                    blockNumber: event.blockNumber
                      ? Number(event.blockNumber)
                      : undefined,
                    txHash: event.transactionHash,
                    tokenSymbol: investedSymbol,
                    amount,
                    rewardsAmount,
                    rewardsTokenSymbol,
                  });
                });
              }
            }
          }

          return { data: result };
        },
        error => getExtendedErrorText(error, t('requestError.getInvested')),
      ),
      providesTags: [cacheTags.account, cacheTags.projects],
    }),
  }),
});
