import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    '& td': {
      height: 90,
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      padding: 0,

      '&:first-of-type': {
        borderTopLeftRadius: '30px',
        borderBottomLeftRadius: '30px',
        overflow: 'hidden',
      },

      '&:last-of-type': {
        borderTopRightRadius: '30px',
        borderBottomRightRadius: '30px',
        overflow: 'hidden',
      },
    },
  },

  projectColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },

  logoContainer: {
    height: '100%',
    width: '100%',
    maxWidth: 152,
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden',
  },

  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s',

    '&:hover': {
      transform: 'scale(1.1)',
    },
  },

  usdAmount: {
    fontSize: 13,
    color: theme.palette.grey[500],
  },

  iconButton: {
    borderRadius: '50%',
    color: theme.palette.primary.main,
  },

  rewardColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },

  rewardedAmount: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
