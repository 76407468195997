import { PointsApi } from 'modules/api';

interface TierProofData {
  expiresAt: number;
  stakeAmount: bigint;
  stakeDuration: number;
  signature: string;
  userAddress: string;
}

const AMOUNT_DECIMALS = 1e8;

export async function getTierProof({
  address,
}: {
  address: string;
}): Promise<TierProofData> {
  const tier = await PointsApi.getInstance().getAccountTier(address);

  return {
    expiresAt: tier.expiresAt,
    stakeAmount: BigInt(
      tier.stakeAmount.multipliedBy(AMOUNT_DECIMALS).toString(),
    ),
    stakeDuration: tier.stakeDuration,
    signature: tier.signature,
    userAddress: tier.userAddress,
  };
}
