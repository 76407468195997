import { Components } from '@mui/material/styles';

export function getMuiTable(): Components['MuiTable'] {
  return {
    styleOverrides: {
      root: {
        borderSpacing: 0,
      },
    },
  };
}
