import { Address } from 'web3';

import { ReadProvider } from '../../api';
import { memoizePromise } from '../../common/utils/memoizePromise';
import IERC20Abi from './abi/IERC20';
import { createImoContract } from './createImoContract';

async function _getInvestToken(
  provider: ReadProvider,
  spender: Address,
): Promise<{
  investTokenAddress: Address;
  investTokenDecimal: number;
}> {
  const IMOSaleContract = createImoContract(provider, { address: spender });

  const investTokenAddress: Address = await IMOSaleContract.methods
    .usdtToken()
    .call();

  const investTokenContract = provider.createContract<typeof IERC20Abi>(
    IERC20Abi,
    investTokenAddress,
  );

  const decimals: bigint = await investTokenContract.methods.decimals().call();

  return {
    investTokenAddress,
    investTokenDecimal: Number(decimals),
  };
}

export const getInvestToken = memoizePromise(
  _getInvestToken,
  (provider: ReadProvider, spender: Address) => {
    return `${provider.chainId}${provider.rpcUrl}${spender}`;
  },
);
