import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Page not found',
    description:
      'The link you clicked may be broken or the page may have been moved.',
    discover: 'Discover IMOs',
    apply: 'Apply for IMO',
  },
};
