import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import IERC20Abi from './abi/IERC20';
import { getInvestToken } from './getInvestToken';

export async function getAllowance(
  provider: ReadProvider,
  data: { userAddress: Address; spender: Address },
): Promise<{
  allowance: BigNumber;
  investTokenAddress: Address;
  investTokenDecimal: number;
}> {
  const { userAddress, spender } = data;

  const { investTokenDecimal, investTokenAddress } = await getInvestToken(
    provider,
    spender,
  );

  const investTokenContract = provider.createContract<typeof IERC20Abi>(
    IERC20Abi,
    investTokenAddress,
  );

  const allowance: bigint = await investTokenContract.methods
    .allowance(userAddress, spender)
    .call();

  return {
    allowance: new BigNumber(utils.fromWei(allowance, investTokenDecimal)),
    investTokenAddress,
    investTokenDecimal,
  };
}
