export default [
  {
    inputs: [
      { internalType: 'address', name: '_projectWallet', type: 'address' },
      { internalType: 'address', name: '_usdtToken', type: 'address' },
      { internalType: 'address', name: '_revenueToken', type: 'address' },
      { internalType: 'uint256', name: '_investmentGoal', type: 'uint256' },
      { internalType: 'address', name: '_operatorKey', type: 'address' },
      { internalType: 'address', name: '_imoFactory', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [], name: 'AlreadyClaimed', type: 'error' },
  { inputs: [], name: 'ClaimPoolPercentageExceeds100', type: 'error' },
  { inputs: [], name: 'EmergencyStopActive', type: 'error' },
  { inputs: [], name: 'EscrowVestingNotSet', type: 'error' },
  { inputs: [], name: 'FundingAlreadyClosed', type: 'error' },
  { inputs: [], name: 'FundingIsAlreadyClosed', type: 'error' },
  { inputs: [], name: 'FundingPeriodEnded', type: 'error' },
  { inputs: [], name: 'FundingPeriodNotEnded', type: 'error' },
  { inputs: [], name: 'IMOTokenNotDeployed', type: 'error' },
  { inputs: [], name: 'InvalidAddress', type: 'error' },
  { inputs: [], name: 'InvalidInvestmentGoal', type: 'error' },
  { inputs: [], name: 'InvalidMilestoneCheckerAddress', type: 'error' },
  { inputs: [], name: 'InvalidOperatorKey', type: 'error' },
  { inputs: [], name: 'InvalidProjectWallet', type: 'error' },
  { inputs: [], name: 'InvalidSignature', type: 'error' },
  { inputs: [], name: 'InvalidStakeProof', type: 'error' },
  { inputs: [], name: 'InvalidStakingContract', type: 'error' },
  { inputs: [], name: 'InvalidTier', type: 'error' },
  { inputs: [], name: 'InvalidTransfer', type: 'error' },
  { inputs: [], name: 'InvestmentGoalReached', type: 'error' },
  { inputs: [], name: 'MilestoneCheckerNotSet', type: 'error' },
  { inputs: [], name: 'MultiplierCannotBeZero', type: 'error' },
  { inputs: [], name: 'NewEndTimeMustBeInFuture', type: 'error' },
  { inputs: [], name: 'NoInvestmentFound', type: 'error' },
  { inputs: [], name: 'NotEligibleToInvest', type: 'error' },
  { inputs: [], name: 'ProofExpired', type: 'error' },
  { inputs: [], name: 'ProxyAdminNotSet', type: 'error' },
  { inputs: [], name: 'TokenNameCannotBeEmpty', type: 'error' },
  { inputs: [], name: 'TokenSymbolCannotBeEmpty', type: 'error' },
  { inputs: [], name: 'USDTApprovalFailed', type: 'error' },
  { inputs: [], name: 'USDTTransferFailed', type: 'error' },
  { inputs: [], name: 'ZeroInvestment', type: 'error' },
  { anonymous: false, inputs: [], name: 'ClaimingEnabled', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bool', name: 'stopped', type: 'bool' },
    ],
    name: 'EmergencyStop',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'investor',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenAllocationMultiplier',
        type: 'uint256',
      },
    ],
    name: 'InvestmentReceived',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'investor',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint8',
        name: 'currentTier',
        type: 'uint8',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'penaltyApplied',
        type: 'bool',
      },
    ],
    name: 'TokensClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'investor',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'TokensDistributed',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ESCROW_PERCENTAGE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FUNDING_PERIOD',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'INITIAL_DEPOSIT_PERCENTAGE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'TIER_LOSS_PENALTY_PERCENTAGE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_newEndTime', type: 'uint256' }],
    name: 'adjustFundingPeriod',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'stakeAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeDuration', type: 'uint256' },
          { internalType: 'uint256', name: 'expiresAt', type: 'uint256' },
          { internalType: 'bytes', name: 'signature', type: 'bytes' },
        ],
        internalType: 'struct IStakeType.StakeProof',
        name: 'stakeProof',
        type: 'tuple',
      },
    ],
    name: 'claimIMOTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'closeFunding',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'escrowVestingContract',
    outputs: [
      { internalType: 'contract EscrowVesting', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fundingEndTime',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fundingGoal',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'investor', type: 'address' },
      {
        components: [
          { internalType: 'uint256', name: 'stakeAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeDuration', type: 'uint256' },
          { internalType: 'uint256', name: 'expiresAt', type: 'uint256' },
          { internalType: 'bytes', name: 'signature', type: 'bytes' },
        ],
        internalType: 'struct IStakeType.StakeProof',
        name: 'stakeProof',
        type: 'tuple',
      },
    ],
    name: 'getActualTokenAllocation',
    outputs: [
      { internalType: 'uint256', name: 'claimableAmount', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getImoTokenAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getInvestmentProgress',
    outputs: [
      { internalType: 'uint256', name: 'currentAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'goalAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'percentageComplete', type: 'uint256' },
      { internalType: 'bool', name: 'soldOut', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getInvestorsCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'investmentAmount', type: 'uint256' },
    ],
    name: 'getTokenAllocation',
    outputs: [
      { internalType: 'uint256', name: 'expectedTokens', type: 'uint256' },
      { internalType: 'uint8', name: 'estimatedTier', type: 'uint8' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'hasClaimed',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'imoClaimPoolPercentage',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'imoFactory',
    outputs: [
      { internalType: 'contract IMOFactory', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'imoToken',
    outputs: [
      {
        internalType: 'contract ERC7641Upgradeable',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'imoTokenName',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'imoTokenSymbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      {
        components: [
          { internalType: 'uint256', name: 'stakeAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakeDuration', type: 'uint256' },
          { internalType: 'uint256', name: 'expiresAt', type: 'uint256' },
          { internalType: 'bytes', name: 'signature', type: 'bytes' },
        ],
        internalType: 'struct IStakeType.StakeProof',
        name: 'stakeProof',
        type: 'tuple',
      },
    ],
    name: 'invest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'investments',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'investors',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isEmergencyStopped',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isFundingClosed',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isSoldOut',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'milestoneCheckerContract',
    outputs: [
      { internalType: 'contract MilestoneChecker', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operatorKey',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'projectWallet',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'proxyAdmin',
    outputs: [
      { internalType: 'contract ProxyAdmin', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'snapshotId', type: 'uint256' },
      { internalType: 'address', name: 'destination', type: 'address' },
    ],
    name: 'rescueSnapshotOnToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'revenueToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_stop', type: 'bool' }],
    name: 'setEmergencyStop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_escrowVesting', type: 'address' },
    ],
    name: 'setEscrowVesting',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_newGoal', type: 'uint256' }],
    name: 'setInvestmentGoal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_milestoneChecker', type: 'address' },
    ],
    name: 'setMilestoneChecker',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'newOperatorKey', type: 'address' },
    ],
    name: 'setOperatorKey',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_multiplier', type: 'uint256' }],
    name: 'setTokenAllocationMultiplier',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tokenAllocationMultiplier',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalFunds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'string', name: '_newName', type: 'string' },
      { internalType: 'string', name: '_newSymbol', type: 'string' },
      {
        internalType: 'uint256',
        name: '_newClaimPoolPercentage',
        type: 'uint256',
      },
    ],
    name: 'updateTokenParameters',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'newImplementation', type: 'address' },
    ],
    name: 'upgradeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'usdtToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
