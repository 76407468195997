import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { DashboardRoutesConfig } from 'modules/dashboard/Routes';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { IContributeData } from 'modules/project/actions/contribute';
import { ContributeDetails } from 'modules/project/components/ContributeDetails';

import { translation } from './translation';

interface IContributePendingProps {
  contributeData?: IContributeData;
  investedSymbol: string;
  onClose?: () => void;
}

export function ContributePending({
  contributeData,
  investedSymbol,
  onClose,
}: IContributePendingProps): ReactElement {
  const { t, keys } = useTranslation(translation);

  return (
    <div>
      <Box textAlign="center">
        <Typography component="div" variant="h5">
          {t(keys.title)}
        </Typography>

        <Typography component="div" mt={3} variant="body2">
          {t(keys.subtitle)}
        </Typography>
      </Box>

      {contributeData && (
        <ContributeDetails
          investedAmount={contributeData.amount.toString()}
          investedSymbol={investedSymbol}
          projectAddress={contributeData.projectAddress}
          transactionHash={contributeData.transactionHash}
        />
      )}

      <Button
        fullWidth
        component={Link}
        size="large"
        to={DashboardRoutesConfig.dashboard.generatePath()}
        onClick={onClose}
      >
        {t(keys.goToDashboard)}
      </Button>
    </div>
  );
}
