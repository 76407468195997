import { JSX } from 'react';
import { DialogContent } from '@mui/material';

import { chainId } from 'modules/api/chainIDs';
import { SwitchNetworkGuard } from 'modules/auth/components/SwitchNetworkGuard';
import { WalletConnectGuard } from 'modules/auth/components/WalletConnectGuard';
import { Dialog } from 'modules/dialogs/components/Dialog';
import { ClaimContent } from 'modules/project/components/ClaimContent';

import { useStyles } from './useStyles';

interface IContributeModalProps {
  projectId: string;
  open: boolean;
  onClose?: () => void;
}

export function ClaimModal({
  projectId,
  open = false,
  onClose,
}: IContributeModalProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
    >
      <WalletConnectGuard>
        <SwitchNetworkGuard chainId={chainId}>
          <DialogContent className={classes.content}>
            <ClaimContent projectId={projectId} onClose={onClose} />
          </DialogContent>
        </SwitchNetworkGuard>
      </WalletConnectGuard>
    </Dialog>
  );
}
