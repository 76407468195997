import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Transaction history',
    header: {
      project: 'Project',
      date: 'Date',
      hash: 'Hash',
      amount: 'Contributed amount',
      rewards: 'Rewards',
    },
    content: {
      date: '{value, time, ::MMMM dd, yyyy}',
      time: '{value, time, ::hh:mm a}',
    },
    noData: 'No data',
  },
};
