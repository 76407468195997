import { IProjectFromApi, IProjectItem } from '../api/types';

export function mapProjectFromApi(project: IProjectFromApi): IProjectItem {
  const startDate = new Date(project.attributes.projectStartDate);
  const endDate = new Date(project.attributes.projectTokensSalesDeadline);
  const totalTokensToRaise = project.attributes.projectTokensAmountToBeRaised
    ? Number(project.attributes.projectTokensAmountToBeRaised)
    : undefined;

  return {
    ...project,
    id: String(project.id),
    attributes: {
      ...project.attributes,
      createdAt: new Date(project.attributes.createdAt),
      updatedAt: project.attributes.updatedAt
        ? new Date(project.attributes.updatedAt)
        : undefined,
      publishedAt: project.attributes.publishedAt
        ? new Date(project.attributes.publishedAt)
        : undefined,
      projectExpectedParticipants: project.attributes
        .projectExpectedParticipants
        ? Number(project.attributes.projectExpectedParticipants)
        : undefined,
      projectTokensIsFlexible: !!project.attributes.projectTokensIsFlexible,
      projectStartDate: startDate,
      projectTokensSalesDeadline: endDate,
      projectTokensPublicAmount: project.attributes.projectTokensPublicAmount
        ? Number(project.attributes.projectTokensPublicAmount)
        : undefined,
      projectTokensAmountToBeRaised: totalTokensToRaise,
      projectTokensPlannedFDVAtLaunch: project.attributes
        .projectTokensPlannedFDVAtLaunch
        ? Number(project.attributes.projectTokensPlannedFDVAtLaunch)
        : undefined,
    },
  } as IProjectItem; // TODO Wrong casting
}

export function mapProjectsFromApi(
  projects: IProjectFromApi[],
): IProjectItem[] {
  return projects?.map((project: IProjectFromApi) =>
    mapProjectFromApi(project),
  );
}
