import { buttonClasses, Components, Theme } from '@mui/material';

export const getMuiButton = (theme: Theme): Components['MuiButton'] => ({
  styleOverrides: {
    root: {
      verticalAlign: 'baseline',
    },
    outlined: {
      border: 0,

      [`&.${buttonClasses.colorPrimary}`]: {
        boxShadow: `0 0 0 2px ${theme.palette.divider}`,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    text: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    sizeMedium: {
      borderRadius: 12,
    },

    sizeLarge: {
      height: 54,
      minHeight: 54,
      borderRadius: 12,
    },

    containedInfo: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
});
