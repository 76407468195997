import { makeStyles } from 'tss-react/mui';

export const useContributeFormStyles = makeStyles()(theme => ({
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8.5),
    margin: theme.spacing(15, 0, 9),
  },

  maxButton: {
    '&&': {
      padding: 0,
      height: 'auto',
      minHeight: 'auto',
      fontSize: 16,
      fontWeight: 700,
    },
  },

  calculatingBox: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  calculatingData: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },

  calculatingAmount: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  hintText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[600],
  },

  baseButton: {
    '&&': {
      padding: 0,
      height: 'auto',
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },

  endLabel: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  endLabelText: {
    '&&': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
}));
