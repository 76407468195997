import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Contribution is pending...',
    subtitle:
      'This may take a moment; you can close this window. Once completed, you can view your staking exposure on the dashboard.',
    goToDashboard: 'Go to Dashboard',
  },
};
