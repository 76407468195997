import BigNumber from 'bignumber.js';

import { api, cacheTags, getWriteProvider, PointsApi } from 'modules/api';
import { getExtendedErrorText } from 'modules/common/utils/getExtendedErrorText';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { t } from 'modules/i18n';

export const { useGetAnkrStakedAmountQuery } = api.injectEndpoints({
  endpoints: build => ({
    getAnkrStakedAmount: build.query<BigNumber, void>({
      queryFn: queryFnNotifyWrapper<void, never, BigNumber>(
        async () => {
          const api = PointsApi.getInstance();

          const provider = await getWriteProvider();

          const { stakeAmount } = await api.getAccountTier(
            provider.currentAccount,
          );
          return { data: stakeAmount };
        },
        error =>
          getExtendedErrorText(error, t('requestError.getAnkrStakedAmount')),
      ),
      providesTags: [cacheTags.account, cacheTags.points],
    }),
  }),
});
