import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';
import { useGetProjectQuery } from 'modules/project/actions/getProject';
import { useGetRewardsQuery } from 'modules/project/actions/getRewards';

export function useHasRewards(projectId: string): boolean {
  const { isConnected } = useConnection();
  const { data: project } = useGetProjectQuery({ projectId });

  const projectAddress = project?.attributes?.projectAddress || '';

  const { data: imoClaimableReward = ZERO } = useGetRewardsQuery(
    { projectAddress },
    { skip: !isConnected || !projectAddress },
  );

  return imoClaimableReward.isGreaterThan(ZERO);
}
