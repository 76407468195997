import { ReactElement, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { OverlaySpinner } from '@ankr.com/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import BigNumber from 'bignumber.js';

import { ZERO } from 'modules/common/const';
import { useContributeMutation } from 'modules/project/actions/contribute';
import { useGetBalanceQuery } from 'modules/project/actions/getBalance';
import { useGetProjectQuery } from 'modules/project/actions/getProject';
import { useGetTokenRewardsMultiplierQuery } from 'modules/project/actions/getTokenRewardsMultiplier';
import { useWaitTransactionDetailsMutation } from 'modules/project/actions/getTransactionDetails';
import {
  ContributeForm,
  IContributePayload,
} from 'modules/project/components/ContributeForm';
import { ContributePending } from 'modules/project/components/ContributePending';
import { ContributeSuccess } from 'modules/project/components/ContributeSuccess';

interface IContributeContentProps {
  projectId: string;
  onClose?: () => void;
}

export function ContributeContent({
  projectId,
  onClose,
}: IContributeContentProps): ReactElement {
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    projectId,
  });

  const investTokenAddress = project?.attributes?.investTokenAddress || '';
  const investedSymbol = project?.attributes?.investedSymbol || '';
  const projectAddress = project?.attributes?.projectAddress || '';

  const { data: balance = ZERO } = useGetBalanceQuery(
    investTokenAddress
      ? {
          tokenAddress: investTokenAddress,
        }
      : skipToken,
  );

  const [contribute, { data: contributeData, isLoading: isContributeLoading }] =
    useContributeMutation();

  const [
    getTransactionDetails,
    { isLoading: isTransactionLoading, isSuccess: isTransactionSuccess },
  ] = useWaitTransactionDetailsMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm<IContributePayload>({
    defaultValues: {
      contributeAmount: '',
    },
  });

  const amountInputValue = watch('contributeAmount');
  const convertedAmount = useMemo(() => {
    const convertedValue = new BigNumber(amountInputValue);

    return convertedValue.isNaN() ? ZERO : convertedValue;
  }, [amountInputValue]);

  const { data: rewardsMultiplierData } = useGetTokenRewardsMultiplierQuery(
    projectAddress
      ? {
          projectAddress,
        }
      : skipToken,
  );

  const maxImoTokenAmount = useMemo(() => {
    const multiplier = rewardsMultiplierData?.multiplier;
    if (!project || !multiplier) {
      return null;
    }

    const goal = project.attributes.investmentGoal;
    const { investedAmount } = project.attributes;

    return goal.minus(investedAmount).multipliedBy(multiplier);
  }, [project, rewardsMultiplierData?.multiplier]);

  const onSubmit = useCallback(
    async ({ contributeAmount }: IContributePayload) => {
      if (projectAddress) {
        try {
          const { receiptPromise } = await contribute({
            projectAddress,
            amount: new BigNumber(contributeAmount),
          }).unwrap();

          await getTransactionDetails(receiptPromise).unwrap();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }
    },
    [contribute, getTransactionDetails, projectAddress],
  );

  const onMax = useCallback(() => {
    setValue('contributeAmount', balance.toNumber(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [balance, setValue]);

  if (isProjectLoading) {
    return <OverlaySpinner sx={{ margin: 'auto' }} />;
  }

  if (isTransactionLoading) {
    return (
      <ContributePending
        contributeData={contributeData}
        investedSymbol={investedSymbol}
        onClose={onClose}
      />
    );
  }

  if (isTransactionSuccess) {
    return (
      <ContributeSuccess
        contributeData={contributeData}
        investedSymbol={investedSymbol}
        onClose={onClose}
      />
    );
  }

  return (
    <ContributeForm
      amount={convertedAmount}
      balance={balance}
      companyName={
        project?.attributes?.projectCompany?.data?.attributes.companyName
      }
      contributeTokenSymbol={project?.attributes?.investedSymbol || ''}
      control={control}
      imoTokenSymbol={project?.attributes?.imoTokenSymbol || ''}
      isDirty={isDirty}
      isLoading={isContributeLoading}
      maxImoTokenAmount={maxImoTokenAmount}
      multiplier={rewardsMultiplierData?.multiplier ?? ZERO}
      projectName={project?.attributes?.projectName || ''}
      onMax={onMax}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
}
