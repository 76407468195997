import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  paper: {
    maxWidth: 696,
    minHeight: 527,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    padding: theme.spacing(15),
  },
}));
