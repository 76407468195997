import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlaySpinner } from '@ankr.com/ui';
import { Button, Typography } from '@mui/material';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';
import { useGetAnkrStakedAmountQuery } from 'modules/dashboard/actions/getAnkrStakedAmount';
import { ProjectsTable } from 'modules/dashboard/components/ProjectsTable/ProjectsTable';
import { ANKR_STAKING_URL } from 'modules/dashboard/const';
import { globalTranslation, mergeTranslations } from 'modules/i18n';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { useGetProjectsQuery } from 'modules/project/actions/getProjects';
import {
  getUserProject,
  IUserProjectStats,
  reduceUserProjects,
} from 'modules/project/actions/getUserProject';
import { ProjectRoutesConfig } from 'modules/project/projectRoutesConfig';
import { store } from 'modules/store';

import { KnownDialogs, useDialog } from '../../../dialogs';
import { Card } from '../Card';
import { TransactionHistoryDialog } from '../TransactionHistoryDialog';
import { translation } from './translation';
import { useStyles } from './useStyles';

const mergedTranslation = mergeTranslations(globalTranslation, translation);

export function MyContribution(): ReactNode {
  const { t, keys } = useTranslation(mergedTranslation);
  const { classes } = useStyles();

  const { data: projects, isLoading: isGetProjectsLoading } =
    useGetProjectsQuery(undefined);

  const { isConnected } = useConnection();

  const { data: stakedAmount = ZERO } = useGetAnkrStakedAmountQuery(undefined, {
    skip: !isConnected,
  });

  const [contributionStats, setContributionStats] = useState<
    IUserProjectStats | undefined
  >();

  const {
    onClose: onTransactionHistoryClose,
    isOpened: isTransactionHistoryOpened,
    onOpen: handleTransactionHistoryOpen,
  } = useDialog(KnownDialogs.transactionHistory);

  useEffect(() => {
    if (!projects || contributionStats) {
      return;
    }

    void (async () => {
      const userProjects = await Promise.all(
        projects.data.map(item => {
          if (!item.attributes.projectAddress) {
            return;
          }

          return store.dispatch(
            getUserProject.initiate({
              projectAddress: item.attributes.projectAddress,
            }),
          );
        }),
      );

      setContributionStats(
        reduceUserProjects(userProjects.map(item => item?.data)),
      );
    })();
  }, [projects, contributionStats]);

  if (isGetProjectsLoading || !projects) {
    return <OverlaySpinner />;
  }

  return (
    <div className={classes.root}>
      <TransactionHistoryDialog
        open={isTransactionHistoryOpened}
        projects={projects}
        onClose={onTransactionHistoryClose}
      />

      <div className={classes.cards}>
        <Card
          className={classes.card}
          description={t(keys.participated)}
          title={
            contributionStats
              ? t(keys.value.participated, {
                  value: contributionStats.activeProjectCount,
                })
              : ''
          }
        />

        <Card
          className={classes.card}
          description={t(keys.myContribution)}
          title={
            contributionStats
              ? t(keys.value.contribution, {
                  value: contributionStats.contributionAmount.toNumber(),
                })
              : ''
          }
          onClick={handleTransactionHistoryOpen}
        />

        <Card
          className={classes.card}
          description={t(keys.stacked)}
          link={ANKR_STAKING_URL}
          title={t(keys.value.staked, {
            value: stakedAmount.toNumber(),
          })}
        />

        <Card
          className={classes.card}
          description={t(keys.earned)}
          title={t(keys.value.earned, {
            value: (contributionStats?.totalEarnedAmount || ZERO).toNumber(),
          })}
          onClick={handleTransactionHistoryOpen}
        />
      </div>

      {contributionStats && contributionStats.contributionAmount.isZero() ? (
        <div className={classes.empty}>
          <Typography variant="body2">{t(keys.empty)}</Typography>

          <Button
            component={Link}
            size="large"
            to={ProjectRoutesConfig.projects.generatePath()}
          >
            {t(keys.discoverProjects)}
          </Button>
        </div>
      ) : (
        <ProjectsTable projects={projects.data} />
      )}
    </div>
  );
}
