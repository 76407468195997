import { ReactElement } from 'react';
import { generatePath, Route, useParams } from 'react-router-dom';

import { createRouteConfig } from 'modules/common/utils/createRouteConfig';
import { DashboardTab } from 'modules/dashboard/components/Tabs';
import { Dashboard } from 'modules/dashboard/screens/Dashboard';

const PATH = `/dashboard/:tab`;

export const DashboardRoutesConfig = createRouteConfig(
  {
    dashboard: {
      path: PATH,
      generatePath: (tab: DashboardTab = DashboardTab.MyContribution) => {
        return generatePath(PATH, { tab });
      },
      useParams: (): { tab?: DashboardTab } => {
        const data = useParams<{ tab: DashboardTab }>();
        const { tab } = data;

        return { tab };
      },
    },
  },
  PATH,
);

export function getDashboardRoutes(): ReactElement {
  return (
    <Route
      element={<Dashboard />}
      path={DashboardRoutesConfig.dashboard.path}
    />
  );
}
